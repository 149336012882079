@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.fjalla-one-regular {
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.buttons::before,
.buttons::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
}

.buttons::before {
  left: -10px;
  background: #c70000;
}

.buttons::after {
  right: -10px;
  background: #c70000;
}

.buttons:hover::before,
.buttons:hover::after {
  width: 58%;
}
